import { useTranslation } from 'react-i18next'
import { styled } from '@linaria/react'
import { Item } from '../common/scroll-selector'
import { numberWithCommas } from '../../utils/number'

const Container = styled.div`
  margin-bottom: 20px;
`

const Amount = styled.p`
  color: var(--secondary-txt);
  span {
    color: var(--txt);
  }
  @media (min-width: 1200px) {
    font-size: 18px;
  }
`

const AmountsContainer = styled.div`
  margin-top: 16px;
`

const PromoListContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 8px;
`

const PromoCard = styled.button`
  width: 140px;
  min-width: 140px;
  border-radius: 6px;
  border: 1px solid var(--txt);
  padding: 10px;
  background-color: var(--tertiary);
  text-align: left;
  transition: all 0.2s ease-in-out;
  &.active {
    background-color: var(--highlight);
  }
  &:disabled {
    opacity: 0.7;
  }
  .title {
    font-family: 'Geometr706-BlkCn-BT';
    text-transform: uppercase;
    font-size: 12px;
    color: var(--txt);
    line-height: 16px;
  }
  .reason {
    font-size: 10px;
    line-height: 12px;
    margin-top: 4px;
    color: var(--secondary-txt);
  }
  @media (min-width: 1200px) {
    width: 180px;
    min-width: 180px;
    .title {
      font-size: 14px;
      line-height: 18px;
    }
    .reason {
      font-size: 12px;
      line-height: 14px;
    }
  }
`

export type Promo = Item & {
  content: string
  bonus_amount: number
  total_credit_amount: number
}

interface PromotionInputProps {
  selectedPromo: Promo | null
  onPromoSelect: (promo: Promo) => void
  promoList: Promo[]
}

const PromotionInput = ({
  selectedPromo,
  promoList,
  onPromoSelect,
}: PromotionInputProps) => {
  const { t } = useTranslation('walletManagement')

  return (
    <Container>
      <PromoListContainer>
        {promoList.map((promo) => (
          <PromoCard
            key={promo.id}
            className={selectedPromo?.id === promo.id ? 'active' : ''}
            disabled={!!promo.disabled}
            onClick={() => onPromoSelect(promo)}>
            <p className="title">{promo.label}</p>
            {!!promo.disabled && (
              <p className="reason">{promo.disabled_reason}</p>
            )}
          </PromoCard>
        ))}
      </PromoListContainer>
      {selectedPromo && selectedPromo.id !== 0 && (
        <AmountsContainer
          className="flx-str-ctr"
          style={{
            flexWrap: 'wrap',
            gap: 12,
            justifyContent: 'space-between',
          }}>
          <Amount>
            {t('bonus-amount')}:{' '}
            <span>${numberWithCommas(+selectedPromo.bonus_amount)}</span>
          </Amount>
          <Amount>
            {t('total-credits')}:{' '}
            <span>${numberWithCommas(+selectedPromo.total_credit_amount)}</span>
          </Amount>
        </AmountsContainer>
      )}
    </Container>
  )
}

export default PromotionInput
