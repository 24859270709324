import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useEffect } from 'react'
import { Portal } from 'react-portal'
import { styled } from '@linaria/react'

import closeModal from '../../utils/closeModal'
import Icon from '../icon'

const Text = styled.p`
  color: #202632;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #eee;
  padding: 10px 15px;
  opacity: 0;
  border-radius: 5px;
  pointer-events: none;
  z-index: 225;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  font-family: 'Avenir-Light';
  font-size: 14px;
  width: max-content;
  max-width: calc(100vw - 40px);
  &.active {
    opacity: 1;
    pointer-events: auto;
  }
  svg {
    margin-right: 7px;
    min-width: 15px;
  }
  .dark {
    & {
      background-color: #fff;
    }
  }
  @media (min-width: 1200px) {
    font-size: 20px;
    padding: 12px 17px;
    svg {
      min-width: 20px;
      margin-right: 10px;
    }
  }
`

interface ToastMessageProps {
  text: string
  isValid?: boolean
}

const ToastMessage = ({ text, isValid }: ToastMessageProps) => {
  const modal = useModal()

  useEffect(() => {
    if (modal.visible) {
      setTimeout(() => closeModal(modal), 3000)
    }
  }, [modal.visible])

  return (
    <Portal>
      <Text className={modal.visible ? 'active' : ''}>
        {isValid && <Icon id="check-2" width="15" height="11" fill="#202632" />}
        {isValid === false && (
          <Icon
            id="close-2"
            width="20"
            height="20"
            fill="#202632"
            opacity="0.8"
          />
        )}
        {text}
      </Text>
    </Portal>
  )
}

export default NiceModal.create(ToastMessage)
