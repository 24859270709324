import { useTranslation } from 'react-i18next'
import { styled } from '@linaria/react'

import type { WithdrawTypes } from '../../types/wallet-management'
import Icon from '../icon'
import { TailSpin } from '../loaders'
import { useState } from 'react'

const Container = styled.div`
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 6px;
  justify-content: center;
  @media (min-width: 1200px) {
    margin-bottom: 24px;
    gap: 10px;
  }
`

const Button = styled.button`
  flex: 1;
  height: 45px;
  background-color: #65676a;
  border-radius: 5px;
  font-size: 12px;
  color: #fff;
  font-family: 'Avenir-Medium';
  &.primary {
    background-color: #df0033;
  }
  svg {
    margin-right: 5px;
    margin-bottom: 1px;
  }
  @media (min-width: 1200px) {
    font-size: 16px;
    height: 50px;
  }
`

interface ActionButtonsProps {
  onAdd: () => void
  onDelete: () => Promise<void>
  type: WithdrawTypes
}

const ActionButtons = ({
  onAdd,
  onDelete,
  type,
}: ActionButtonsProps) => {
  const { t } = useTranslation('walletManagement')
  const [deleteLoading, setDeleteLoading] = useState(false)

  return (
    <Container>
      <Button className="flx-ctr primary" onClick={onAdd}>
        <Icon id="plus" width="12" fill="#fff" />
        {t('add')}{' '}
        {type === 'bank' ? `${t('bank-account')}` : `${t('usdt-address')}`}
      </Button>
      <Button
        className="flx-ctr"
        onClick={async () => {
          setDeleteLoading(true)
          await onDelete()
          setDeleteLoading(false)
        }}>
        {deleteLoading ? (
          <TailSpin color="#fff" width={20} height={20} />
        ) : (
          <>
            <Icon id="trash-outline" width="12" color="#fff" />
            {t('delete')}{' '}
            {type === 'bank' ? `${t('bank-account')}` : `${t('usdt-address')}`}
          </>
        )}
      </Button>
    </Container>
  )
}

export default ActionButtons
