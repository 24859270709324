import { Router } from '@tanstack/router'
import qs from 'qs'

import { indexRoute } from './routes'
import { affiliateHomeRoute } from './routes/affiliate-home'
import { announcementsRoute } from './routes/announcements'
import { casinoRoute } from './routes/casino'
import { competitionsRoute } from './routes/competitions'
import { contactUsRoute } from './routes/contact-us'
import { depositRoute } from './routes/deposit'
import { kycBankRoute } from './routes/kyc-bank'
import { kycBankListRoute } from './routes/kyc-bank-list'
import { depositIndexRoute } from './routes/deposit/deposit'
import { paymentFormRoute } from './routes/deposit/payment-form'
import { footerPageRoute } from './routes/footer-page'
import { profileRoute } from './routes/profile'
import { affiliateRoute } from './routes/profile/affiliate'
import { affiliateIndexRoute } from './routes/profile/affiliate/affiliate'
import { affiliatePolicyRoute } from './routes/profile/affiliate/affiliate-policy'
import { memberListRoute } from './routes/profile/affiliate/member-list'
import { commissionRoute } from './routes/profile/commission'
import { financialTransactionRoute } from './routes/profile/financial-transaction'
import { freeBonusRoute } from './routes/profile/free-bonus'
import { freeSpinsRoute } from './routes/profile/free-spins'
import { inboxRoute } from './routes/profile/inbox'
import { kycRoute } from './routes/profile/kyc'
import { profileIndexRoute } from './routes/profile/profile'
import { referralRoute } from './routes/profile/referral'
import { invitationCodesRoute } from './routes/profile/referral/invitation-codes'
import { myInvitationRoute } from './routes/profile/referral/my-invitation'
import { referralIndexRoute } from './routes/profile/referral/referral'
import { referralPolicyRoute } from './routes/profile/referral/referral-policy'
import { transactionHistoryRoute } from './routes/profile/transaction-history'
import { promotionsRoute } from './routes/promotions'
import { promoTermsRoute } from './routes/promotions/promo-terms'
import { promotionsIndexRoute } from './routes/promotions/promotions'
import { registerRoute } from './routes/register'
import { rootRoute } from './routes/root'
import { sportsRoute } from './routes/sports'
import { vipRoute } from './routes/vip'
import { withdrawRoute } from './routes/withdraw'
import { withdrawIndexRoute } from './routes/withdraw/withdraw'
import { withdrawFormRoute } from './routes/withdraw/withdraw-form'
import { ErrorPage } from './components/common'
import { gameRoute } from './routes/game'
import { QS_CONFIG } from './constants/api'
import { claimHistoryRoute } from './routes/profile/claim-history'
import { loginRoute } from './routes/login'
import { notFoundRoute } from './routes/not-found'
import { affiliateSmsRoute } from './routes/affiliate-sms'
import { casinoAdsRoute } from './routes/casino-ads'
import { sportsAdsRoute } from './routes/sports-ads'
import { sorteoAdsRoute } from './routes/sorteo-ads'

const routeTree = rootRoute.addChildren([
  indexRoute,
  affiliateHomeRoute,
  announcementsRoute,
  casinoRoute,
  competitionsRoute,
  contactUsRoute,
  kycBankRoute,
  kycBankListRoute,
  depositRoute.addChildren([
    depositIndexRoute,
    paymentFormRoute,
  ]),
  footerPageRoute,
  profileRoute.addChildren([
    profileIndexRoute,
    affiliateRoute.addChildren([
      affiliateIndexRoute,
      affiliatePolicyRoute,
      memberListRoute,
    ]),
    commissionRoute,
    financialTransactionRoute,
    freeBonusRoute,
    freeSpinsRoute,
    inboxRoute,
    kycRoute,
    referralRoute.addChildren([
      referralIndexRoute,
      invitationCodesRoute,
      myInvitationRoute,
      referralPolicyRoute,
    ]),
    transactionHistoryRoute,
    claimHistoryRoute,
  ]),
  promotionsRoute.addChildren([promotionsIndexRoute, promoTermsRoute]),
  registerRoute,
  sportsRoute,
  withdrawRoute.addChildren([withdrawIndexRoute, withdrawFormRoute]),
  vipRoute,
  gameRoute,
  loginRoute,
  affiliateSmsRoute,
  casinoAdsRoute,
  sportsAdsRoute,
  sorteoAdsRoute,
  notFoundRoute, // Should be always last
])

function customStringifier(searchObj: Record<string, unknown>) {
  return qs.stringify(searchObj, { ...QS_CONFIG, addQueryPrefix: true })
}

function customParser(searchString: string) {
  return qs.parse(searchString, { ...QS_CONFIG, ignoreQueryPrefix: true })
}

export const router = new Router({
  routeTree,
  defaultPreload: 'intent',
  defaultErrorComponent: ({ error }) => (
    <ErrorPage errorMessage={error.message} />
  ),
  stringifySearch: customStringifier,
  parseSearch: customParser,
})

declare module '@tanstack/router' {
  interface Register {
    router: typeof router
  }
}
