import { Route, lazy } from '@tanstack/router'

import type { CasinoSearch } from '../types/route-search'
import { rootRoute } from './root'

const Casino = lazy(() => import('../pages/casino'))

export const casinoRoute = new Route({
  getParentRoute: () => rootRoute,
  path: 'casino',
  component: Casino,
  validateSearch: (search: Record<string, unknown>): CasinoSearch => {
    return {
      category:
        search.category !== undefined && search.category !== null
          ? +search.category
          : 0,
      platform: typeof search.platform === 'string' ? search.platform : '',
      error: typeof search.error === 'string' ? search.error : '',
      message: typeof search.message === 'string' ? search.message : '',
    }
  },
})
