import { useModal } from '@ebay/nice-modal-react'
import { useEffect } from 'react'
import { isDesktop, isIOS } from 'react-device-detect'

import { AndroidDownloadBanner } from '../components/common'
import type { ApiResponse } from '../types/api'
import { api } from '../utils/api-client'

const useAndroidDownloadBanner = () => {
  const androidDownloadBanner = useModal(AndroidDownloadBanner)

  useEffect(() => {
    if (
      isIOS ||
      isDesktop ||
      window.ReactNativeWebView ||
      location.pathname !== '/'
    )
      return
    const checkForUpdate = async () => {
      const response = await api.post<
        ApiResponse<{
          android: {
            download_link: string
            version: string
          }
        }>
      >('/ajax_datav2.php', {
        type: 'get_app_version',
      })
      const { status, info } = response.data

      if (status === 1) {
        setTimeout(
          () =>
            androidDownloadBanner.show({
              downloadUrl: info.android.download_link,
            }),
          1000,
        )
      }
    }
    checkForUpdate()
  }, [])
}

export default useAndroidDownloadBanner
