import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useState } from 'react'
import { css } from '@linaria/core'
import closeModal from '../../utils/closeModal'
import Modal from '../common/modal'
import Deposit from './deposit'
import Tabs from './tabs'
import Withdraw from './withdraw'

const container = css`
  width: 1100px;
  max-width: 1100px;
  padding: 0;
  border-radius: 10px;
  border-width: 0px;
  background-color: var(--secondary);
`

type WalletManagementModalProps = {
  walletManagementDefaultTab?: {
    tab?: number
    page?: number
  }
}

const WalletManagementModal = ({
  walletManagementDefaultTab,
}: WalletManagementModalProps) => {
  const modal = useModal()
  const [activeTab, setActiveTab] = useState(
    walletManagementDefaultTab?.tab ?? 0,
  )

  const showWalletManagementModal = (page?: number, tab?: number) => {
    modal.show({ walletManagementDefaultTab: { page, tab } })
  }

  return (
    <Modal id="wallet-management-modal" containerStyle={container}>
      <Tabs
        activeTab={activeTab}
        setActiveTab={tab => {
          setActiveTab(tab)
          showWalletManagementModal(0)
        }}
      />
      {activeTab === 0 ? (
        <Deposit
          showWalletManagementModal={showWalletManagementModal}
          walletManagementDefaultTab={walletManagementDefaultTab}
        />
      ) : (
        <Withdraw
          closeWalletManagementModal={() => closeModal(modal)}
          showWalletManagementModal={showWalletManagementModal}
          walletManagementDefaultTab={walletManagementDefaultTab}
        />
      )}
    </Modal>
  )
}

export default NiceModal.create(WalletManagementModal)
