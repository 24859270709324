import { Route, lazy } from '@tanstack/router'

import { rootRoute } from './root'
import { KycBankSearch } from '../types/route-search'

const KycBankList = lazy(() => import('../pages/kyc-bank-list'))

export const kycBankListRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/kyc-bank-list',
  component: KycBankList as () => JSX.Element,
  validateSearch: (search: Record<string, unknown>): KycBankSearch => {
    return search
  },
})
