import { Route, lazy } from '@tanstack/router'

import { rootRoute } from './root'
import { KycBankSearch } from '../types/route-search'

const KycBank = lazy(() => import('../pages/kyc-bank'))

export const kycBankRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/kyc-bank',
  component: KycBank,
  validateSearch: (search: Record<string, unknown>): KycBankSearch => {
    return search
  },
})
